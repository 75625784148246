import styled from '@emotion/styled';
import { Flex, Heading } from '@square-enix-private/vaquita-ui';
import { position } from 'styled-system';
import { transform } from '~/custom/styled-system';

export const Wrapper = styled(Flex)`
  ${position};
  width: 100%;
  z-index: 1;
  ${transform};
  text-transform: capitalize;
`;

export const SectionTitle = styled(Heading)`
  color: #fff;
  font-family: korolev-condensed, sans-serif;
  filter: drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.6));
`;

export const SectionSubTitle = styled(Heading)`
  width: 100%;
  margin: 0;
  font-family: korolev-condensed, sans-serif;
`;
